<template>
   <form novalidate class="md-layout md-gutter md-alignment-center-center form-content"  
    method="post" @submit.prevent="login">
           
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">Login</div>
        </md-card-header>
        <md-card-content>
          <!-- <p>Your account doesn't have access to this page. To proceed,
          please login with an account that has access.</p> -->
          <md-card v-if="error.show_error" class="md-accent message-box text-center" md-with-hover>
            <md-card-content>
              Usuario y contraseña no coinciden. por favor intente de nuevo.
            </md-card-content>
          </md-card>
            
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field >
                <md-icon class="md-primary">person</md-icon>
                
                <label for="id_username">Nombre de usuario:</label>
                <md-input   name="username" id="username" autocomplete="username" v-model="username"
                            :disabled="sending " maxlength="150"/>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field >
                <md-icon class="md-primary">lock</md-icon>
                  <label for="id_password">Contraseña:</label>
                  <md-input v-model="password" type="password" name="password" 
                    autocomplete="password" required="required" id="id_password"
                    @keyup.enter="login" :disabled="sending "/>
<!--                              <md-input type="text" name="username" autofocus="autofocus" autocapitalize="none" 
                                autocomplete="username" maxlength="150" required="required" id="id_username"/> 
                             <md-input  @focus="focusInput('agentName')" name="agent-name" id="agent-name" autocomplete="given-name" v-model="form.agentName" :disabled="sending" />  -->
                </md-field>
    <!-- <span class="md-error" v-if="!$v.form.agentName.required && $v.form.agentName.$dirty">Nombre de Agente es requerido</span> 
                    <span class="md-error" v-else-if="!$v.form.agentName.minLength && $v.form.agentName.$dirty">Nombre muy corto</span>  -->
                </div>
            </div>

            <p><a href="">Recuperar contraseña?</a></p>

          </md-card-content>
          <md-card-actions>
<!--   type="button"    @click="validateUser" :disabled="sending"  @click="validateUser" -->
          <md-button  type="button" class="md-primary" @click="login" :disabled="sending">Iniciar sesión</md-button>
        </md-card-actions>
         <md-progress-bar md-mode="indeterminate" v-if="sending" />
      </md-card>
  </form>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Login',
  components: {
    
  },
  data: ()=>({
    error:{
      show_error:false,
      message:''
    },

    username:null,
    password:null,
    sending: false
  }),
  methods: {
      login: function () {
        let username = this.username
        let password = this.password
        this.sending = true
        setTimeout(() => {
          
          this.$store.dispatch('login', { username, password })
          .then((resp) => {console.log(resp) 
            this.$router.push('/')
            this.sending = false
          })
          .catch(err =>{
            this.sending = false

            // console.log(err.response.data)
            const data = err.response.data
            
            if('login' in data){
              this.error.show_error = true
              this.error.message = data.login[0]
            }
          })

        }, 1000);
      }
    }
}
</script>
<style lang="scss">
  .form-content{
    height: calc(90vh - 64px - 40px);
  }
  .text-center{
    text-align: center;
  }
  .message-box>.md-card-content{
    padding: 10px 15px;
  }

</style>