<template>
  <div >
    <md-table v-model="cotizaciones" md-card >
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Cotizaciones</h1>
        </div>
        
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Buscar por id, nombre o email " v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>

      <md-table-empty-state
        md-label="Cotizacion no encontrada"
        :md-description="`No se encontro resultados para: '${search}'. Por favor intente nuevamente, o cree una nueva cotizacion`">
        <md-button class="md-primary md-raised" @click="goToCotizador">Crear Cotización</md-button>
      </md-table-empty-state>

      <md-table-row slot="md-table-row" slot-scope="{ item }" >
        <md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Nombre" md-sort-by="name">{{ item.nombre_cliente }}</md-table-cell>
        <md-table-cell md-label="Email" md-sort-by="email">{{ item.email_cliente }}</md-table-cell>
        <md-table-cell md-label="Telefono" md-sort-by="title">{{ item.telefono_cliente }}</md-table-cell>
        <md-table-cell md-label="Fecha" md-sort-by="gender">{{ item.created_at }}</md-table-cell>
        <md-table-cell md-label="Pdf" md-sort-by="gender">
          <a :href="$appConfig.adminUrl+'empresa/cotizacion/pdf/'+item.id" target="_blank" class="text-center">
            <i class="material-icons" id="34">print</i>
          </a>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'



export default {
  name: 'CotizacionesList',
  components: {
    // HelloWorld
  },
  data: ()=>({
    cotizaciones: [],
    search:'',
  }),
  mounted:function(){
    this.getListCotizaciones();
   
    
  },
  methods:{
    getListCotizaciones(){
      var params = {};
      const search = this.search;
      if(search != undefined || search != null || search != ''){
       
        params['search'] = search;
      }
      
      this.$api({url:`empresa/cotizacion/list/`, method:'GET', params})
      .then(resp => {
        // console.log(resp.data)
        this.cotizaciones = resp.data
        
      })
      // .catch(err => {
        
      // })
    },
    searchOnTable () {
      this.getListCotizaciones();
        // this.searched = searchByName(this.users, this.search)
        
    },
    goToCotizador(){
       this.$router.push({ name: 'Cotizador'})
    }
  }
}
</script>

<style lang="scss">
.md-table-content{
  width: 100%;
}
</style>